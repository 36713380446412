<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','status_name']" :submit_preprocessing="submit_preprocessing"
			@changeStatus='changeStatus' ref="list">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div>
						<a-statistic title="总数量" :value="tracing_count.total_num" />
					</div>
					<div>
						<a-statistic title="已使用数量" :value="tracing_count.use_num" />
					</div>
					<div @click='go_list'>
						<a-statistic title="未使用数量" :value-style="{ color: '#397dee' }"
							:value="tracing_count.left_num" />
					</div>
				</div>
			</template>

			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加溯源码</a-button>
			</template>

			<template slot="status_name" slot-scope="data">
				<p>{{data.record.status_name}}
				<p>
				<p><a-progress :show-info="data.record.rate==100?false:true" style='width:200px;' :percent="data.record.rate" /></p>
				<p v-if='data.record.expected_time'>预计完成时间：{{data.record.expected_time}}</p>
			</template>
			<template slot="operation" slot-scope="data">
				<p >
					<span v-if="data.record.status >= 3">
						<a @click='go_list(data.record)'>查看详情</a>
					</span>
					<a-divider type="vertical" v-if="data.record.status >= 3" />
					<span v-if="data.record.status==3"><a @click='set_img(data.record)'>生成图片</a></span>
					<a-divider v-if="data.record.status==3" type="vertical" />
					<span>
						<a-dropdown>
							<a v-auth="'change'">下载</a>
							<a-menu slot="overlay">
								<a-menu-item v-if='data.record.url'>
									<a :href="data.record.url" download>下载图片 </a>
								</a-menu-item>
								<a-menu-item>
									<a :href="data.record.txt_url" download>下载txt</a>
								</a-menu-item>
							</a-menu>
						</a-dropdown>
					</span>
					<a-divider  type="vertical"  v-if="data.record.status==2||data.record.status==1||data.record.status==4||data.record.status==3"/>
					<span v-if="data.record.status==2||data.record.status==1||data.record.status==3||data.record.status==4">
						<a @click='up_list(data.record)'>刷新</a>
					</span>
				</p>

			

			</template>
		</TableList>
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">

		</EditPop>
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import {
		message
	} from 'ant-design-vue';
	import {
		getGoods
	} from '@/api/cost'
	import {
		createCode,
		getTracingList,
		getTracingCount,
		getFactorySelect,
		TracingSave,
		getCodeDetail,
		getCodeCount,
		getScanCodeLog,
		getWarehouseSelect
	} from "@/api/traceability";

	let columns = [{
			title: "序号",
			dataIndex: "id",
		},
		
		{
			title: "创建基地",
			dataIndex: "factory_sap_name",
		},
		
		{
			title: "生成数量",
			dataIndex: "need_num",
		},
		{
			title: "品项",
			dataIndex: "goods_name",
		},
		{
			title: "已使用数量",
			dataIndex: "use_num",
		},
		{
			title: "创建时间",
			dataIndex: "create_time",
		},
		{
			title: "完成时间",
			dataIndex: "update_time",
		},
		{
			title: "生成状态",
			dataIndex: "status_name",
			scopedSlots: {
				customRender: "status_name"
			}
		},
		// 
		{
			title: "操作",
			width: 300,
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	export default {
		name: "gpoodIndex",
		components: {
			EditPop,
			Form,
			TableList,
		},
		data() {
			return {
				tracing_count: {},
				get_table_list: getTracingList,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				visible: false,
				form_data_seo: {
					list: [{
							type: "range_date",
							name: "range_date",
							title: "创建时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
						},
						{
							type: "range_date",
							name: "use",
							title: "完成时间",
							options: {},
							start: {
								name: 'complete_start'
							},
							end: {
								name: 'complete_end'
							},
						},
						{
							type: 'select',
							name: 'factory_sap_id',
							placeholder: '请选择基地',
							title: '基地',
							list: [],

						},
						{
							type: 'text',
							name: 'goods_name',
							placeholder: '品项名称/品项Id',
							title: '品项',

						},
					],
					...this.$config.form_data_seo
				},
				form_data: {
					title: '添加',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {},
						},

						{
							type: 'select',
							name: 'factory_sap_id',
							placeholder: '请选择基地',
							title: '溯源码基地',
							list: [],
							options: {
								rules: [{
									required: true,
									message: "请选择基地"
								}]
							},
						},
						{
							type: 'integer',
							name: 'need_num',
							title: '生成数量',
							options: {
								rules: [{
									required: true,
									message: "请输入生成数量"
								}]
							},
						},
						{
							type: 'text',
							name: 'prefix',
							title: '溯源码前缀',
							options: {
								rules: [{
									required: false,
									message: "请输入溯源码前缀"
								}]
							},
						},
						{
							type: 'select',
							name: 'goods_id',
							placeholder: '请选择品项',
							title: '创建品项',
							list: [],
							options: {
								rules: [{
									message: "请选择商品分类"
								}]
							},
						},

					]
				},
				submit_preprocessing: {
					array_to_string: ['goods_name']
				}
			};
		},
		async created() {
			this.get_info()
			this.get_Tracing_Count()
		},
		methods: {
			set_img(data) {
				createCode({
					data: {
						task_id: data.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list()
				})
			},
			up_list() {
				this.$refs.list.get_list();
			},
			changeStatus(data) {
				this.get_Tracing_Count(data)
			},
			get_Tracing_Count(data) {
				getTracingCount({
					data: data ? data : {}
				}).then(res => {
					this.tracing_count = res.data.list
				})
			},
			go_list(data) {
				this.$router.push({
					path: 'code',
					query: {
						task_id: data.id
					}
				})
			},
			get_info() {
				getGoods().then(res => {
					res.data.list.forEach(it => {
						it.key = it.sap_id
						it.value = it.goods_title + '(' + it.sap_id + ')'
					})
					this.form_data.list.forEach(item => {
						if (item.name == 'goods_id') {
							item.list = res.data.list

						}

					})

				})

				// 仓库数据
				getFactorySelect().then(res => {
					this.$method.set_form_list(this.form_data.list, 'factory_sap_id', 'list', res.data.list);
					this.$method.set_form_list(this.form_data_seo.list, 'factory_sap_id', 'list', res.data.list);
				})
			},

			add() {
				this.form_data.title = "添加"
				this.visible = true;

			},
			edit(record) {
				this.form_data.title = "编辑"
				let data = record
				this.form_data.list.forEach(item => {
					if (item.type == 'upload') {
						let val = {};
						val[item.name] = {
							fileList: [{
								url: data[item.name]
							}]
						}
						item.options.initialValue = data[item.name] ? [{
							uid: data[item.name],
							name: data[item.name],
							status: 'done',
							url: data[item.name]
						}] : [];

					} else if (item.type != 'slot') {
						item.options.initialValue = data[item.name];
					}
				})
				this.visible = true;
			},
			del(record) {
				goodsDelete({
					data: {
						id: record.id
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			submit(e) {
				let data = e.values;
				if (!data.id) {
					data.id = '';
				}
				if (data.need_num < 1) {
					message.error('生成数量需大于0');
					return false
				}
				TracingSave({
					data: {
						...data
					},
					info: true,
				}).then(res => {
					this.visible = false;
					this.$refs.list.get_list();
				})
			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.max_price_tips {
		margin-left: 99px;
		margin-top: -30px;
		font-size: 13px;
	}

	.float_left {
		margin-right: 8px;
	}

	.max_price_form {
		.item {
			display: flex;

			.right {
				flex: 1;

				.input_number {
					width: 100%;
				}
			}
		}
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}

		div:nth-child(3) {
			cursor: pointer;
		}
	}
</style>